import React from "react"
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import ArticlePreview from '../components/article-preview'
import '../components/base.css'

class BlogPage extends React.Component {
    render() {
      const posts = get(this, 'props.data.allContentfulBlogPost.edges')
      const pageName = "blog"  //This is needed for linking the right thing in the article-preview
  
      return (
        <Layout pageTitle="GevDev Blogs" SEODescription="GevDev's thoughts on everything, including Coding, Technology, Photography and Cars.">
          <div style={{ background: '#fff' }}>
            <div className="wrapper">
              <ul className="article-list">
                {posts.map(({ node }) => {
                  return (
                    <li key={node.slug}>
                      <ArticlePreview article={node} page={pageName}/>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </Layout>
      )
    }
  }
  
  export default BlogPage
  
  export const pageQuery = graphql`
    query BlogPostIndexQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            tags
            heroImage {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                )
            }
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `
  