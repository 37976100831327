import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from './article-preview.module.css'

const tagUrlPrefix = "/tag/"

const ArticlePreview = ({ article, page }) => (
  <div>
    <Link to={`/${page}/${article.slug}`} style={{textDecoration: `none`}}>
    <GatsbyImage image={article.heroImage.gatsbyImageData} alt="" />
    <h3 className={styles.previewTitle}>
      <span>{article.title}</span>
    </h3>
    <small>{article.publishDate}</small>
    <p
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    {article.tags && article.tags.map(tag => (
      <p className={styles.tag} key={tag}>
        <Link to={tagUrlPrefix+tag}>{tag}</Link>
      </p>
    ))}
    </Link>
  </div>
)

export default ArticlePreview

